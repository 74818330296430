import styled, { keyframes } from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const Bubble = keyframes`
  0% {
		box-shadow: 0 0 0 3px rgba(136, 102, 56,0.3);
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	100% {
		box-shadow: 0 0 0 100px rgba(255,65,108,0);
		opacity: 0;
		transform: scale3d(1, 1, 1);
	}
`

const SectionWrapper = styled.div`
  background: ${props =>
    props.halfBG
      ? "linear-gradient(180deg, #fbfbf9 50%, white 50%)"
      : "#fbfbf9"};
  padding: 30px 0 30px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1600px) {
    padding: 30px 0 30px;
  }
  @media only screen and (max-width: 1366px) {
    padding: 30px 0 30px;
  }
  @media only screen and (max-width: 667px) {
    padding: 30px 0 30px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0px 0 60px;
  }

  .section_wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 480px) {
    position: relative;
  }

  .image {
    position: relative;
    width: 700px;
    margin: 0 0 0 auto;
    @media only screen and (max-width: 1280px) {
      width: 50%;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      background: linear-gradient(180deg, #fbfbf9 50%, #f8f2ea 50%);
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media only screen and (max-width: 768px) {
      background: linear-gradient(180deg, #fbfbf9 50%, #f8f2ea 50%);
    }
  }
  .content {
    max-width: 685px;
    position: absolute;
    background-color: #f8f2ea;
    padding: 30px 45px 30px 45px;
    z-index: 1;
    @media only screen and (max-width: 1220px) {
      max-width: 600px;
    }
    @media only screen and (max-width: 991px) {
      position: relative;
      max-width: 100%;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      position: relative;
    }
    @media only screen and (max-width: 480px) {
      max-width: 100%;
      position: none;
      padding: 30px 0px 30px 0px;
    }
    h2 {
      color: ${themeGet("colors.textColor", "#333333")};
      font-size: 24px;
      line-height: 36px;
      font-weight: 300;
      letter-spacing: 0.96px;
      margin-bottom: 27px;
      @media only screen and (max-width: 1366px) {
        font-size: 24px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        text-align: left;
      }
      @media only screen and (max-width: 480px) {
        max-width: 100%;
        text-align: left;
        font-size: 24px;
        padding: 0 20px 0 20px;
      }
    }
    h1 {
      margin-top: 50px;
      font-family: "Mulish";
      color: ${themeGet("colors.textColor", "#333333")};
      font-size: 180px;
      line-height: 28px;
      font-weight: 300;
      margin-bottom: 0px;
      @media only screen and (max-width: 1366px) {
        font-size: 180px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        font-size: 180px;
        text-align: left;
      }
      @media only screen and (max-width: 480px) {
        max-width: 100%;
        font-size: 180px;
        text-align: left;
        padding: 0 20px 0 20px;
      }
    }
    @media only screen and (max-width: 480px) {
      max-width: 100%;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.32;
    color: ${themeGet("colors.textColor", "#333333")};
    margin-bottom: 0px;
    @media only screen and (max-width: 1366px) {
      margin-bottom: 0px;
    }
    @media only screen and (max-width: 768px) {
      text-align: left;
    }
    @media only screen and (max-width: 480px) {
      text-align: left;
      font-size: 14px;
      padding: 0 20px 0 20px;
    }
  }

  .testimonial_icon {
    position: absolute;
    right: 80px;
    width: 120px;
    cursor: pointer;
    @media only screen and (max-width: 1370px) {
      display: none;
    }
  }
`

export const PlayButton = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${themeGet("colors.white", "#ffffff")};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${themeGet("colors.textColor", "#333333")};
  cursor: pointer;
  outline: 0;
  z-index: 2;
  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
    svg {
      width: 30px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
    top: 0;
    left: 0;
    border-radius: inherit;
  }
  &:focus {
    &:after {
      animation: ${Bubble} 0.5s ease-out forwards;
    }
  }
`

export default SectionWrapper
