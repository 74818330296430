import "@redq/reuse-modal/lib/index.css"

import Fade from "react-reveal/Fade"
import Icon from "react-icons-kit"
import Image from "gatsby-image"
import React from "react"
import { closeModal, openModal } from "@redq/reuse-modal"
import { play } from "react-icons-kit/entypo/play"

import Box from "../../components/Box"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import TestimonialIcon from "../../common/assets/image/elegant/watch_testimonial_button.svg"
import Text from "../../components/Text"
import SectionWrapper, { ContentWrapper, PlayButton } from "./testimonial.style"

const Testimonial = ({ halfBG, testimonialContent, videoUrl }) => {
  const IntroVideo = () => (
    <iframe
      title="ReactivePro"
      width="560"
      height="315"
      src={videoUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )

  const CloseModalButton = () => (
    <Button
      className="modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  )
  // Video modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "100%",
          height: "100%",
          x: 0,
          y: 0,
        },
      },
      component: IntroVideo,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    })
  }

  const { text, name, image } = testimonialContent

  return (
    <SectionWrapper halfBG={halfBG}>
      <Container width="1280px" className="section_wrapper">
        <ContentWrapper>
          <Fade up delay={200}>
            <div className="content">
              <Heading as="h1" content={"“"} />
              <Heading content={text} />
              <Fade up delay={300}>
                <Text content={name} />
              </Fade>
              <Fade up delay={300}>
                <img
                  onClick={handleVideoModal}
                  src={TestimonialIcon}
                  alt="testimonial icon"
                  className="testimonial_icon"
                />
              </Fade>
            </div>
          </Fade>
          <Fade up delay={200}>
            <div className="image">
              <Image alt="Testimonial" fluid={image.childImageSharp.fluid} />
              <Box>
                <PlayButton tabIndex="0" onClick={handleVideoModal}>
                  <Icon icon={play} size={50} />
                </PlayButton>
              </Box>
            </div>
          </Fade>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default Testimonial
